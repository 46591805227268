import React, { useEffect } from "react";
import CounterArea from "../headertwo/CounterArea";
import GamerArea from "../headertwo/GamerArea";
import OfferArea from "../headertwo/OfferArea";
import Disclaimer from "./Disclaimer";
import ImpressumArea from "./ImpressumArea";

function Areabg() {
  return (
    <div className="area-bg-two">
      <ImpressumArea />
      <Disclaimer />
    </div>
  );
}

export default Areabg;
