import React from "react";

function Partnerships() {
  return (
    <section className="team-area pt-115">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title title-style-two text-center mb-60">
              <h2 className="title">Partnerships</h2>
            </div>
          </div>
        </div>
        <div className="team-thumb-wrap">
          <div className="row justify-content-center">
            <div className="single-thumb">
              <a href="https://yepple.io/" target="_blank">
                <img
                  width={150}
                  src="assets/img/partners/partner_yepple.png"
                  alt="Yepple"
                />
              </a>
              <br></br>
              <br></br>

              <h6> Yepple </h6>
            </div>
            <div className="single-thumb">
              <a href="https://cardanolands.com/" target="_blank">
                <img
                  width={150}
                  src="assets/img/partners/partner_clands.png"
                  alt="CardanoLands"
                />
              </a>
              <br></br>
              <br></br>

              <h6> CardanoLands </h6>
            </div>
          </div>
          {/* <div className="team-btn text-center mt-40">
            <a href="/our-squad" className="btn">
              view all members
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Partnerships;
