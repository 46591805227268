import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

const FAQ = () => {
  return (
    <section className="faq-section mb-50" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="section-title title-style-two text-center mb-60">
              <h2 className="title">FAQ</h2>
            </div>
          </div>
          <div className="col-lg-8 mx-auto">
            <div className="faq-content wow fadeInRight" data-wow-delay=".2s">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5 className="title">What is Adaki?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Adaki is a web3 brand and NFT platform built on the
                      Cardano Blockchain. Our name is derived from ADA (the
                      currency of the Cardano Blockchain) and KI, which means
                      "energy" in Japanese. We aim to bring creative energy to
                      the world of blockchain technology and build a community
                      of passionate individuals who share our vision.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5 className="title">What are NFTs?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      NFTs (Non-Fungible Tokens) are unique digital assets that
                      are verified on a blockchain. They can be used to
                      represent all kinds of digital and physical items, from
                      art to music to real estate.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h5 className="title">
                      What kind of products or services does Adaki offer?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Adaki offers a unique collection of NFTs (non-fungible
                      tokens) drawn in an anime style, perfect for fans of anime
                      and the NFT space.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h5 className="title">How can I purchase Adaki NFTs?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Adaki NFTs are published on our partnerwebsite and can be
                      purchased with ADA or DJED. For more information on the
                      availability and purchase of Adaki NFTs, please visit our
                      Discord Server.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <h5 className="title">
                      How can I join the Adaki community?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      You can join the Adaki community by following us on social
                      media and participating in our Discord server.
                      <br></br>
                      <a href="https://twitter.com/AdakiNFT" target="_blank">
                        Twitter
                      </a>
                      <br></br>
                      <a href="http://discord.adaki.io" target="_blank">
                        Discord
                      </a>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <h5 className="title">
                      What sets Adaki apart from other web3 brands?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Adaki is unique because we are dedicated to combining
                      Japanese aesthetics, anime designs and the Cardano
                      blockchain. We create unique NFT artworks that take full
                      advantage of the possibilities of blockchain technology
                      and put our community at the centre. We believe the future
                      of Web3 is bright and exciting, and we are ready to shape
                      that future with our fans.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <h5 className="title">Can I collaborate with Adaki?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Yes, we are always open to collaborations with like-minded
                      individuals and projects. Please reach out to us on social
                      media (Twitter & Discord) to discuss potential
                      collaborations.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <h5 className="title">
                      Is Adaki committed to sustainability?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Yes, Adaki is committed to sustainability and minimizing
                      our environmental impact. We use renewable energy sources
                      and work with eco-friendly suppliers whenever possible.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
