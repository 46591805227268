import React from "react";

function OfferArea() {
  return (
    <section className="pb-70">
      <div className="container ">
        <div className="row "></div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <a href="https://www.twitter.com/AdakiNFT" target="_blank">
              <div className="offer-item mb-30">
                <h4 className="title">Twitter</h4>
                <p>Stay up to date</p>
                <h5 className="overlay-text">Adaki</h5>
              </div>
            </a>
          </div>

          <div className="col-lg-4 col-md-6">
            <a href="http://discord.adaki.io" target="_blank">
              <div className="offer-item mb-30">
                <h4 className="title">Discord</h4>
                <p>Meet the community</p>
                <h5 className="overlay-text">Adaki</h5>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OfferArea;
