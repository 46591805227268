import React, { useEffect } from "react";
import PrivacyPolicy from "./PrivacyPolicy";

function Areabg() {
  return (
    <div className="area-bg-two">
      <PrivacyPolicy />
    </div>
  );
}

export default Areabg;
