import React, { useEffect } from "react";

import Banner from "./Banner";

function Areabg() {
  return (
    <div className="">
      <Banner />
    </div>
  );
}

export default Areabg;
