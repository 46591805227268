import "./Game.css";
import React, { useState } from "react";

function Game() {
  const [isBlur, setIsBlur] = useState(true);

  const handlePlayNow = () => {
    setIsBlur(false);
  };

  return (
    <div className={`gamediv`}>
      <iframe
        className={`gamediv-iframe ${
          isBlur ? "blur" : "gamediv-iframe-enabled"
        }`}
        src={`${process.env.PUBLIC_URL}/assets/AdakiRoadmap/index.html`}
        // src={"https://roadmapgame.adaki.io"}
        width={816}
        height={624}
      ></iframe>

      {isBlur && (
        <button
          className="btn btn-primary play-now-button"
          onClick={handlePlayNow}
        >
          <h3>Play NOW</h3>
        </button>
      )}
    </div>
  );
}

export default Game;
