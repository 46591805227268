import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";

import "./CookieManager.css";

function CookieManager() {
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [useNonEssentialCookies, setUseNonEssentialCookies] = useState(false);

  useEffect(() => {
    if (cookieAccepted) {
      // Aktiviere Google Analytics und setze den entsprechenden Cookie
      ReactGA.initialize("G-ZEKDGQSFMW");
      ReactGA.send({
        hitType: "pageview",
        page: "/my-path",
        title: "Custom Title",
      });
    }
  }, [cookieAccepted]);

  useEffect(() => {
    // Überprüfe, ob der Benutzer den Cookie-Consent bereits akzeptiert hat
    const cookieConsentAccepted = localStorage.getItem("cookieConsentAccepted");
    if (cookieConsentAccepted) {
      setCookieAccepted(true);
    }
  }, []);

  const handleCheckboxChange = (event) => {
    setUseNonEssentialCookies(event.target.checked);
  };

  const handleAcceptCookie = () => {
    // Speichere den Status des Cookie-Consent im lokalen Speicher des Benutzers
    localStorage.setItem("cookieConsentAccepted", true);
    setCookieAccepted(true);
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {!cookieAccepted && (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="CookiesAccepted"
          style={{
            background: "#1f2029",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          buttonClasses="btn"
          buttonWrapperClasses="cookie-button mb-3 animate__animated animate__fadeInUp animate__delay-.6s"
          buttonStyle={{ fontSize: "30px", background: "transparent" }}
          expires={80}
          debug={true}
          onAccept={handleAcceptCookie}
          extraCookieOptions={{
            sameSite: "strict",
            secure: true,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "1em", fontSize: "50px" }}>
              <h1>This website uses cookies!</h1>
            </div>
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={useNonEssentialCookies}
                onChange={handleCheckboxChange}
              />
              <div style={{ marginLeft: "0.5em" }}>
                <a>I only allow necessary cookies.</a>
              </div>
            </label>
            <br></br>
            <br></br>
            <div>
              <h6>
                We try to collect as little data from you as possible. If you
                have questions regarding our cookies or our policy, don't
                hesitate to contact us.
              </h6>
            </div>
            <br></br>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          </div>
        </CookieConsent>
      )}
    </div>
  );
}

export default CookieManager;
