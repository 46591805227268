import React from "react";
import Slider from "react-slick";

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="far fa-arrow-alt-circle-left"></i>
    </button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="far fa-arrow-alt-circle-right"></i>
    </button>
  );
}
function Sneeks() {
  var settings = {
    centerMode: true,
    autoplay: true,
    infinite: true,
    speed: 3000,
    centerPadding: "0",
    slidesToShow: 3,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    arrows: false,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          // slidesToShow: 3,
          // centerPadding: "180px",
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "0px",
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          // slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "50px",
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          // slidesToShow: 2,
          centerPadding: "0px",
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "0px",
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "50px",
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="gallery-area">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-8">
            <div className="section-title title-style-two text-center mb-60">
              <h2 className="title">Sneaks</h2>
            </div>
          </div>
          <div className="col-xl-7">
            <Slider className="gallery-active" {...settings} rows={1}>
              <div className="gallery-item">
                <img src="assets/img/sneeks/sneek_1.png" alt="" width={250} />
              </div>
              <div className="gallery-item">
                <img src="assets/img/sneeks/sneek_2.png" alt="" width={250} />
              </div>
              <div className="gallery-item">
                <img src="assets/img/sneeks/sneek_3.png" alt="" width={250} />
              </div>
              <div className="gallery-item">
                <img src="assets/img/sneeks/sneek_4.png" alt="" width={250} />
              </div>
              <div className="gallery-item">
                <img src="assets/img/sneeks/sneek_5.png" alt="" width={250} />
              </div>
              <div className="gallery-item">
                <img src="assets/img/sneeks/sneek_6.png" alt="" width={250} />
              </div>
              <div className="gallery-item">
                <img src="assets/img/sneeks/sneek_7.png" alt="" width={250} />
              </div>
              <div className="gallery-item">
                <img src="assets/img/sneeks/sneek_8.png" alt="" width={250} />
              </div>
              <div className="gallery-item">
                <img src="assets/img/sneeks/sneek_9.png" alt="" width={250} />
              </div>
            </Slider>
          </div>
        </div>
        <div className="slider-nav" />
      </div>
    </div>
  );
}

export default Sneeks;
