import React from "react";
import { motion } from "framer-motion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Tilt from "react-parallax-tilt";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
import "./Parallax.css";

function Information() {
  return (
    <section className="just-gamer-area pt-30 pb-40 mt-30">
      <div className="just-gamer-shape" />
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="section-title title-style-two text-center mb-60">
            <h2 className="title">About the Project</h2>
          </div>
          <div className="col-xl-6 col-lg-8 order-2 order-xl-1">
            <div className="just-gamer-content-box">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="Community">
                  <div className="section-title">
                    <h2 className="title">
                      Adaki <span>Community</span>
                    </h2>
                    <p>
                      At Adaki, we believe that community is the backbone of our
                      success. We're not just building a brand, we're building a
                      movement - and that starts with the people who make up our
                      community. Our goal is to create an environment where
                      everyone feels welcome, supported and empowered to explore
                      the endless possibilities of the Cardano Blockchain and
                      the NFT space. We put our community at the forefront of
                      everything we do, and we're committed to fostering a
                      culture of creativity, collaboration and inclusivity. We
                      also believe that community is the key to mass adoption of
                      blockchain technology. By fostering a strong community of
                      blockchain enthusiasts, artists and creators, we're
                      helping to spread the word about the benefits of
                      blockchain and NFTs to a wider audience. Our community is
                      made up of people from all walks of life who are united by
                      their passion for the blockchain, their creativity and
                      their commitment, of which we couldn't be more proud!
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      Community is the backbone of our success and we put our
                      community first.
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      We believe community is key to mass adoption of blockchain
                      technology and NFTs.
                    </p>
                  </div>
                </Tab>

                <Tab eventKey="homprofilee" title="Cardano">
                  <div className="section-title">
                    <h2 className="title">
                      Why <span>Cardano</span> ?
                    </h2>
                    <p>
                      Are you ready to join the next big thing in the world of
                      blockchain? Look no further than Adaki, built on the
                      rock-solid foundations of the Cardano Blockchain! We
                      believe that Cardano is the best long-term option for
                      building a sustainable future in the web3 space, with its
                      unmatched energy efficiency and groundbreaking features.
                      And we're not alone - the Cardano community is thriving
                      and growing every day, with passionate individuals who
                      share our vision for a better, more decentralized world.
                      Join us on this journey and become a part of the Cardano
                      community today!
                    </p>
                    <p>
                      Just like anime, Cardano is a complex and intricate
                      creation filled with depth and potential for endless
                      exploration. Both anime and Cardano have loyal and
                      dedicated communities that appreciate their attention to
                      detail and commitment to excellence. Just as the
                      characters in anime embark on epic journeys and face
                      difficult challenges, Cardano is constantly evolving and
                      pushing the boundaries of what is possible within its own
                      ecosystem.
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      Built on the rock-solid foundations of Cardano Blockchain
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      A complex creation with potential for endless exploration
                    </p>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-8"
            style={{
              height: "100%",
            }}
          >
            <MouseParallaxContainer
              className="parallax"
              containerStyle={{
                width: "100%",
                height: "auto",
                // display: "grid",
                // gridTemplateColumns: "auto auto auto auto auto",
                // zIndex: 1,
              }}
              globalFactorX={0.5}
              globalFactorY={0.5}
              resetOnLeave
            >
              {/* <div
                className="position-relative d-flex justify-content-center align-items-center h-auto"
                // style={{
                //   height: "300px",
                // }}
              > */}
              <div
                className="text-center text-xxl-mid mx-auto parallax-div-infopage"
                style={{ position: "relative" }}
              >
                <MouseParallaxChild
                  factorX={0.5}
                  factorY={0.5}
                  className="parallax-child"
                  // style={{
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "center",
                  //   width: "auto",
                  //   height: "100%",
                  //   position: "absolute",
                  //   top: 0,
                  //   left: 150,
                  // }}
                >
                  <Tilt
                    perspective={500}
                    glareEnable={true}
                    glareMaxOpacity={0}
                    glarePosition="all"
                    tiltAngleXInitial={0}
                    tiltAngleYInitial={0}
                    tiltMaxAngleX={0}
                    tiltMaxAngleY={20}
                    scale={1.02}
                    trackOnWindow={true}
                    // tiltReverse={true}
                  >
                    <div className="parallax-div">
                      <img
                        src="assets/img/parallax/parralaxx.png"
                        alt=""
                        width={450}
                      />
                    </div>
                  </Tilt>
                </MouseParallaxChild>
                <MouseParallaxChild
                  factorX={0.8}
                  factorY={0.8}
                  className="parallax-child"
                  // style={{ left: 50, top: 50 }}
                >
                  <Tilt
                    perspective={500}
                    glareEnable={true}
                    glareMaxOpacity={0}
                    glarePosition="all"
                    tiltAngleXInitial={0}
                    tiltAngleYInitial={0}
                    tiltMaxAngleX={20}
                    tiltMaxAngleY={20}
                    scale={1.02}
                    trackOnWindow={true}
                    // tiltReverse={true}
                  >
                    <div>
                      <h2>Community</h2>
                    </div>
                  </Tilt>
                </MouseParallaxChild>

                {/* <Tilt
                  perspective={500}
                  glareEnable={true}
                  glareMaxOpacity={0}
                  glarePosition="all"
                  tiltAngleXInitial={0}
                  tiltAngleYInitial={0}
                  tiltMaxAngleX={0}
                  tiltMaxAngleY={20}
                  scale={1.05}
                  trackOnWindow={true}
                  // tiltReverse={true}
                >
                  <div
                    style={{
                      width: "300px",
                      height: "300px",
                      backgroundColor: "grey",
                      marginBottom: "50px",
                    }}
                  >
                    <h1>React Parallax Tilt 👀</h1>
                  </div>
                </Tilt> */}
              </div>
              {/* </div> */}
            </MouseParallaxContainer>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Information;
