import React from "react";
import "animate.css";
import Tilt from "react-parallax-tilt";
import { NavLink } from "react-router-dom";
function Banner() {
  return (
    <div id="parallax" className="banner-area banner-bg">
      <div className="container" style={{ overflow: "hidden" }}>
        <div className="row justify-content-center">
          <div className="col-xxl-8 col-lg-9">
            <div className="banner-content text-center">
              <Tilt
                perspective={500}
                glareEnable={true}
                glareMaxOpacity={0}
                glarePosition="all"
                tiltAngleXInitial={0}
                tiltAngleYInitial={0}
                tiltMaxAngleX={20}
                tiltMaxAngleY={30}
                scale={1.05}
                trackOnWindow={true}
                tiltReverse={true}
              >
                <h1 className="title">Error</h1>
              </Tilt>
              <div
                className="title layer animate__animated animate__fadeInUp animate__delay-.4s"
                data-depth="-0.2"
              >
                <Tilt
                  perspective={500}
                  glareEnable={true}
                  glareMaxOpacity={0}
                  glarePosition="all"
                  tiltAngleXInitial={0}
                  tiltAngleYInitial={0}
                  tiltMaxAngleX={25}
                  tiltMaxAngleY={15}
                  scale={1.05}
                  trackOnWindow={true}
                  tiltReverse={true}
                >
                  <h1 className="title">404</h1>
                </Tilt>
              </div>
              <div className="banner-btn animate__animated animate__fadeInUp animate__delay-.6s">
                <NavLink to="/" className="btn">
                  Back to home
                  <i className="fas fa-play-circle" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
