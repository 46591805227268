import React from "react";
import { motion } from "framer-motion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Tilt from "react-parallax-tilt";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
import "./Parallax.css";

function Information2() {
  return (
    <section className="just-gamer-area pt-30 pb-40">
      <div className="just-gamer-shape" />
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-lg-8 order-2 order-xl-0">
            <div className="just-gamer-content-box">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="Story">
                  <div className="section-title">
                    <h2 className="title">
                      Adaki <span>Story</span>
                    </h2>
                    <p>
                      Enter the world of Adaki, a place filled with magic,
                      mystery and danger. Long ago, portals appeared on Earth,
                      leading to other worlds and dimensions, some of which were
                      filled with terrifying creatures. To defend themselves,
                      brave fighters known as "Gatekeepers" emerged, equipped
                      with powerful magic and artifacts to protect people from
                      these monsters. As the number of portals decreased, the
                      Gatekeepers were eventually forgotten, and the portals
                      became a distant myth. However, now in the year 2027, the
                      portals have reappeared and people are once again faced
                      with the danger of the unknown. Scientists work with the
                      ancient secret society of the Gatekeepers to understand
                      the portals and figure out how to control them. But as a
                      comet passes by Earth, strange and powerful forces are
                      unleashed and the connection between the portals and the
                      comet becomes clearer. Will you join the fight and become
                      a Walker, journeying through the portals into unknown
                      worlds, discovering the secrets of the comet and
                      protecting humanity from the dangers that lurk in the
                      shadows? Explore the Adaki universe and be a part of this
                      epic adventure!
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>The world of Adaki is filled with magic and danger.</p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      Embark on a thrilling journey to defend humanity and
                      unlock the secrets of the Adaki universe - become a
                      Gatekeeper today!
                    </p>
                  </div>
                </Tab>

                <Tab eventKey="homprofilee" title="Gaming">
                  <div className="section-title">
                    <h2 className="title">
                      Blockchain <span>Gaming</span>
                    </h2>
                    <p>
                      In today's fast-paced world, gaming has become an integral
                      part of our lives. But what if we told you that there's a
                      new way of gaming that takes it to a whole new level? We
                      are talking about Blockchain Gaming, and we believe that
                      it is the future of the gaming industry. At Adaki, we are
                      dedicated to exploring the vast potential of this emerging
                      technology and integrating it into our gamified
                      experience. We want to create a web game that is not just
                      entertaining but also rewarding for the players. By using
                      NFTs, we will provide users with the ability to level up
                      their accounts by completing quests and earning in-game
                      rewards. We understand the value of user experience and
                      our goal is to create a seamless and engaging platform
                      that makes the most of the blockchain technology. We see a
                      future where Blockchain Gaming is not just a trend but a
                      new standard in the gaming industry. However, it is
                      essential to note that we are not making any promises. Our
                      team is working diligently to develop and enhance our
                      gamified ecosystem to provide the best possible experience
                      for our users. As the technology and industry continue to
                      evolve, we will adapt and improve to stay at the forefront
                      of this exciting new development. In conclusion, we
                      believe that Blockchain Gaming is a game-changer and we
                      are committed to exploring its vast potential. Our
                      gamified ecosystem is just the beginning and we are
                      excited to see where this journey takes us. Join us on
                      this adventure and let's explore the world of Blockchain
                      Gaming together.
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      Adaki combines blockchain and games to create a gamified
                      experience that allows users to level up and have fun
                      while actively using their NFTs.
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      We see huge market potential for this system in the
                      gaming.
                    </p>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-8"
            style={{
              height: "100%",
            }}
          >
            <MouseParallaxContainer
              className="parallax"
              containerStyle={{
                width: "100%",
                height: "auto",
                // display: "grid",
                // gridTemplateColumns: "auto auto auto auto auto",
                // zIndex: 1,
              }}
              globalFactorX={0.5}
              globalFactorY={0.5}
              resetOnLeave
            >
              {/* <div
                className="position-relative d-flex justify-content-center align-items-center h-auto"
                // style={{
                //   height: "300px",
                // }}
              > */}
              <div
                className="text-center text-xxl-mid mx-auto parallax-div-infopage"
                style={{ position: "relative" }}
              >
                <MouseParallaxChild
                  factorX={0.5}
                  factorY={0.5}
                  className="parallax-child"
                >
                  <Tilt
                    perspective={500}
                    glareEnable={true}
                    glareMaxOpacity={0}
                    glarePosition="all"
                    tiltAngleXInitial={0}
                    tiltAngleYInitial={0}
                    tiltMaxAngleX={0}
                    tiltMaxAngleY={20}
                    scale={1.02}
                    trackOnWindow={true}
                    // tiltReverse={true}
                  >
                    <div className="parallax-div">
                      <img
                        src="assets/img/parallax/girlparallax.png"
                        alt=""
                        width={450}
                      />
                    </div>
                  </Tilt>
                </MouseParallaxChild>
                <MouseParallaxChild
                  factorX={0.8}
                  factorY={0.8}
                  className="parallax-child"
                >
                  <Tilt
                    perspective={500}
                    glareEnable={true}
                    glareMaxOpacity={0}
                    glarePosition="all"
                    tiltAngleXInitial={0}
                    tiltAngleYInitial={0}
                    tiltMaxAngleX={20}
                    tiltMaxAngleY={20}
                    scale={1.02}
                    trackOnWindow={true}
                    // tiltReverse={true}
                  >
                    <div className="parallax-div">
                      <h2>Utility</h2>
                    </div>
                  </Tilt>
                </MouseParallaxChild>

                {/* <Tilt
                  perspective={500}
                  glareEnable={true}
                  glareMaxOpacity={0}
                  glarePosition="all"
                  tiltAngleXInitial={0}
                  tiltAngleYInitial={0}
                  tiltMaxAngleX={0}
                  tiltMaxAngleY={20}
                  scale={1.05}
                  trackOnWindow={true}
                  // tiltReverse={true}
                >
                  <div
                    style={{
                      width: "300px",
                      height: "300px",
                      backgroundColor: "grey",
                      marginBottom: "50px",
                    }}
                  >
                    <h1>React Parallax Tilt 👀</h1>
                  </div>
                </Tilt> */}
              </div>
              {/* </div> */}
            </MouseParallaxContainer>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Information2;
