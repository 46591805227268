import React from "react";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import AreaBg from "../components/headerone/AreaBg";
import Banner from "../components/headerone/Banner";
import BannerCountdown from "../components/headerone/BannerCountdown";

function IndexOne() {
  return (
    <>
      <HeaderOne />
      <main>
        <Banner />
        {/* <BannerCountdown /> */}
        <AreaBg />
      </main>
      <Footer />
    </>
  );
}

export default IndexOne;
