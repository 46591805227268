import React from "react";
import Tilt from "react-parallax-tilt";

function Team() {
  return (
    <section className="our-squad-area grey-bg" id="team">
      <div className="container">
        <div className="col-lg-8 mx-auto">
          <div className="section-title title-style-two text-center mb-60">
            <h2 className="title">Team</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
            <div className="squad-item">
              <div className="squad-thumb">
                <Tilt
                  perspective={500}
                  // glareEnable={true}
                  // glareMaxOpacity={100}
                  // glarePosition="bottom"
                  // glarePosition="all"
                  tiltAngleXInitial={0}
                  tiltAngleYInitial={0}
                  tiltMaxAngleX={30}
                  tiltMaxAngleY={20}
                  scale={1.02}
                  // trackOnWindow={true}
                  // tiltReverse={true}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img src="assets/img/sneeks/team_mixu.png" alt="Mixu" />
                  </div>
                </Tilt>
              </div>
              <div className="squad-content">
                <div className="squad-info">
                  <h4 className="title">
                    <a href="https://twitter.com/MiXu_Crypto" target="_blank">
                      MiXu
                    </a>
                  </h4>
                  <span>Founder</span>
                  <span>CEO</span>

                  {/* <span>Chief Information Officer</span> */}
                </div>
                <div className="squad-social">
                  <div className="social-list">
                    <a href="https://twitter.com/MiXu_Crypto" target="_blank">
                      <i className="fab fa-twitter-square" />
                    </a>
                    {/* <a href="/#">
                      <i className="fab fa-vimeo" />
                    </a>
                    <a href="/#">
                      <i className="fab fa-pinterest" />
                    </a>
                    <a href="/#">
                      <i className="fab fa-twitch" />
                    </a> */}
                  </div>
                  {/* <span className="follow-us">follow me</span> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
            <div className="squad-item">
              <div className="squad-thumb">
                <Tilt
                  perspective={500}
                  // glareEnable={true}
                  // glareMaxOpacity={2}
                  // glarePosition="all"
                  tiltAngleXInitial={0}
                  tiltAngleYInitial={0}
                  tiltMaxAngleX={30}
                  tiltMaxAngleY={20}
                  scale={1.02}
                  // trackOnWindow={true}
                  // tiltReverse={true}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src="assets/img/sneeks/team_quinn.png" alt="" />
                  </div>
                </Tilt>
              </div>
              <div className="squad-content">
                <div className="squad-info">
                  <h4 className="title">
                    <a href="https://twitter.com/Qu1nn_web3" target="_blank">
                      Qu1nn
                    </a>
                  </h4>
              
                </div>
                <div className="squad-social">
                  <div className="social-list">
                    <a href="https://twitter.com/Qu1nn_web3" target="_blank">
                      <i className="fab fa-twitter-square" />
                    </a>
                
                  </div>
               
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-9">
            <div className="squad-item">
              <div className="squad-thumb">
                <Tilt
                  perspective={500}
                  tiltAngleXInitial={0}
                  tiltAngleYInitial={0}
                  tiltMaxAngleX={30}
                  tiltMaxAngleY={20}
                  scale={1.02}
                
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src="assets/img/sneeks/team_kumori.png" alt="" />
                  </div>
                </Tilt>
              </div>
              <div className="squad-content">
                <div className="squad-info">
                  <h4 className="title">
                    <a
                      href="https://twitter.com/Kumori_Shinobi"
                      target="_blank"
                    >
                      Kumori
                    </a>
                  </h4>
                  <span>Collaboration Manager</span>
                </div>
                <div className="squad-social">
                  <div className="social-list">
                    <a
                      href="https://twitter.com/Kumori_Shinobi"
                      target="_blank"
                    >
                      <i className="fab fa-twitter-square" />
                    </a>
                  </div>
             
                </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}

export default Team;
