import React from "react";

function ImpressumArea() {
  return (
    <section className="contact-area black-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="contact-wrap">
              <div className="contact-title">
                <h2 className="title"></h2>
                <div className="mx-auto">
                  <p>
                    Non-german visitors: German law requires to publish the
                    following text in german language. It contains information
                    regarding legal form of the company, authorship, data
                    protection and privacy informations, disclaimer, copyright
                    notices and german tax information.
                    <br></br>
                    <br></br>
                    <h5>Impressum</h5>
                    <br></br>
                    <p>
                      Angaben gemäß § 5 TMG <br></br>
                      Xunlai UG (haftungsbeschränkt) <br></br> <br></br>
                      Drosselweg 7 <br></br> 86381 <br></br> Krumbach <br></br>
                      Deutschland <br></br> <br></br>
                      Vertreten durch Michael Schindler (Geschäftsführer / CEO)
                      <br></br>
                      <br></br>
                    </p>
                    <h5>Kontakt</h5>
                    <p>
                      info@xunlai.io <br></br> www.xunlai.io <br></br>{" "}
                    </p>
                    <br></br>
                    <h5>Eintragung im Handelsregister:</h5>
                    Registergericht: Memmingen <br></br> Registernummer: HRB
                    20199 <br></br>Umsatzsteuer-ID gemäß §27 a
                    Umsatzsteuergesetz: Wird gerade beantragt <br></br>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-8">
            <div className="contact-info-wrap">
              <div className="contact-info-list">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="content">
                      <p>
                        Drosselweg 7, 86381 Krumbach <br /> Germany
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-envelope-open" />
                    </div>
                    <div className="content">
                      <p>info@xunlai.io</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ImpressumArea;
