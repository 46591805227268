import React from "react";

const Raster = () => {
  return (
    <>
      <div className="container mt-80">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="about-title text-center">
              <div className="section-title title-style-two text-center mb-60">
                <h2 className="title">We are Cardano</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="about-info-item">
              <div className="about-info-icon">
                <img src="assets/img/icon/gp_icon05.png" alt="" />
              </div>
              <div className="about-info-content">
                <h4 className="title">Discover</h4>
                <p>
                  Unlock the full potential of NFTs, immerse yourself in a
                  gamified ecosystem, and connect with a community of innovators
                  and explorers who share your passion.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="about-info-item">
              <div className="about-info-icon">
                <img src="assets/img/icon/gp_icon05.png" alt="" />
              </div>
              <div className="about-info-content">
                <h4 className="title">Story</h4>
                <p>
                  Embark on a journey through Adaki's storytelling and explore a
                  world of limitless possibilities, where every moment is a
                  chance to create a new adventure.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="about-info-item">
              <div className="about-info-icon">
                <img src="assets/img/icon/gp_icon05.png" alt="" />
              </div>
              <div className="about-info-content">
                <h4 className="title">Transparent</h4>
                <p>
                  Adaki prioritizes transparency in all aspects of its
                  operations, ensuring that users have full access to
                  information and resources to make informed decisions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="about-info-item">
              <div className="about-info-icon">
                <img src="assets/img/icon/gp_icon05.png" alt="" />
              </div>
              <div className="about-info-content">
                <h4 className="title">Sustainable</h4>
                <p>
                  Adaki's use of the eco-friendly Cardano blockchain is more
                  than just a technological solution; it's a reflection of our
                  deep commitment to sustainability and long-term thinking.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="about-info-item">
              <div className="about-info-icon">
                <img src="assets/img/icon/gp_icon05.png" alt="" />
              </div>
              <div className="about-info-content">
                <h4 className="title">Entertainment</h4>
                <p>
                  Entertainment is important because it allows us to create
                  engaging and enjoyable experiences for our users, helping to
                  build strong and lasting connections with our brand.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="about-info-item">
              <div className="about-info-icon">
                <img src="assets/img/icon/gp_icon05.png" alt="" />
              </div>
              <div className="about-info-content">
                <h4 className="title">Community</h4>
                <p>
                  Adaki believes in the power of community, and we strive to
                  create a space where people can come together to share their
                  ideas, collaborate, and build a better future for all.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Raster;
