import React, { useState } from "react";

function PrivacyPolicy() {
  return (
    <section className="contact-area black-bg">
      <div className="container mx-auto">
        <div className="m-10 d-flex justify-content-center">
          <div className="contact-wrap">
            <div className="contact-title">
              <h2 className="title">
                <span>Data Protection Declaration</span>
              </h2>
              <div className="mx-auto">
                <h5>
                  1) Information on the Collection of Personal Data and Contact
                  Details of the Controller
                </h5>

                <p>
                  1.1 We are pleased that you are visiting our website and thank
                  you for your interest. On the following pages, we inform you
                  about the handling of your personal data when using our
                  website. Personal data is all data with which you can be
                  personally identified.
                  <br />
                  1.2 The controller in charge of data processing on this
                  website, within the meaning of the General Data Protection
                  Regulation (GDPR), is Michael Schindler, Xunlai UG
                  (haftungsbeschränkt), Drosselweg 7, 86381 Krumbach,
                  Deutschland, Tel.: 015223061242, E-Mail: info@xunlai.io. The
                  controller in charge of the processing of personal data is the
                  natural or legal person who alone or jointly with others
                  determines the purposes and means of the processing of
                  personal data.
                  <br />
                  1.3 This website uses SSL or TLS encryption for security
                  reasons and to protect the transmission of personal data and
                  other confidential content (e.g. orders or inquiries to the
                  controller). You can recognize an encrypted connection by the
                  character string https:// and the lock symbol in your browser
                  line.
                </p>
                <br></br>
                <h5>2) Data Collection When You Visit Our Website</h5>
                <p>
                  When using our website for information only, i.e. if you do
                  not register or otherwise provide us with information, we only
                  collect data that your browser transmits to our server
                  (so-called "server log files"). When you visit our website, we
                  collect the following data that is technically necessary for
                  us to display the website to you:
                </p>
                <br></br>
                <ul>
                  <li>- Our visited website</li>
                  <li>- Date and time at the moment of access</li>
                  <li>- Amount of data sent in bytes</li>
                  <li>- Source/reference from which you came to the page</li>
                  <li>- Browser used</li>
                  <li>- Operating system used</li>
                  <li>- IP address used (if applicable: in anonymized form)</li>
                </ul>
                <br></br>
                <p>
                  Data processing is carried out in accordance with Art. 6 (1)
                  point f GDPR on the basis of our legitimate interest in
                  improving the stability and functionality of our website. The
                  data will not be passed on or used in any other way. However,
                  we reserve the right to check the server log files
                  subsequently, if there are any concrete indications of illegal
                  use.
                </p>
                <br></br>
                {/*  Cookies */}
                <h5>3) Cookies</h5>
                <p>
                  In order to make your visit to our website attractive and to
                  enable the use of certain functions, we use so-called cookies
                  on various pages. These are small text files that are stored
                  on your end device. Some of the cookies we use are deleted
                  after the end of the browser session, i.e. after closing your
                  browser (so-called session cookies). Other cookies remain on
                  your terminal and enable us or our partner companies
                  (third-party cookies) to recognize your browser on your next
                  visit (persistent cookies). If cookies are set, they collect
                  and process specific user information such as browser and
                  location data as well as IP address values according to
                  individual requirements. Persistent cookies are automatically
                  deleted after a specified period, which may vary depending on
                  the cookie. You can check the duration of the respective
                  cookie storage in the overview of the cookie settings of your
                  web browser. <br></br>
                  In some cases, cookies are used to simplify the ordering
                  process by saving settings (e.g. remembering the content of a
                  virtual shopping basket for a later visit to the website). If
                  personal data are also processed by individual cookies set by
                  us, the processing is carried out in accordance with Art. 6
                  (1) point b GDPR either for the execution of the contract or
                  in accordance with Art. 6 (1) point f GDPR to safeguard our
                  legitimate interests in the best possible functionality of the
                  website and a customer-friendly and effective design of the
                  page visit. <br></br>
                  We work together with advertising partners who help us to make
                  our website more interesting for you. For this purpose,
                  cookies from partner companies are also stored on your hard
                  drive when you visit our website (third-party cookies). You
                  will be informed individually and separately about the use of
                  such cookies and the scope of the information collected in
                  each case within the following sections.<br></br>
                  Please note that you can set your browser in such a way that
                  you are informed about the setting of cookies and you can
                  decide individually about their acceptance or exclude the
                  acceptance of cookies for certain cases or generally. Each
                  browser differs in the way it manages the cookie settings.
                  This is described in the help menu of each browser, which
                  explains how you can change your cookie settings. You will
                  find these for the respective browsers under the following
                  links:<br></br>- Internet Explorer:
                  <a
                    href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                  >
                    support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                  </a>
                  <br></br>- Firefox:
                  <a
                    href="https://www.mozilla.org/en-US/privacy/websites/"
                    target="_blank"
                  >
                    www.mozilla.org/en-US/privacy/websites/
                  </a>
                  <br></br>- Chrome:
                  <a
                    href="https://support.google.com/accounts/answer/61416"
                    target="_blank"
                  >
                    support.google.com/accounts/answer/61416
                  </a>
                  <br></br>- Safari:
                  <a
                    href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                    target="_blank"
                  >
                    support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                  </a>
                  <br></br>- Opera:{" "}
                  <a
                    href="https://help.opera.com/en/latest/web-preferences/"
                    target="_blank"
                  >
                    help.opera.com/en/latest/web-preferences/
                  </a>
                  <br></br>
                  Please note that the functionality of our website may be
                  limited if cookies are not accepted.
                </p>
                <br></br>
                {/* Data Collection When You Visit Our Website */}

                <h5>2) Data Collection When You Visit Our Website</h5>
                <p>
                  When using our website for information only, i.e. if you do
                  not register or otherwise provide us with information, we only
                  collect data that your browser transmits to our server
                  (so-called "server log files"). When you visit our website, we
                  collect the following data that is technically necessary for
                  us to display the website to you:
                </p>
                <ul>
                  <li>Our visited website</li>
                  <li>Date and time at the moment of access</li>
                  <li>Amount of data sent in bytes</li>
                  <li>Source/reference from which you came to the page</li>
                  <li>Browser used</li>
                  <li>Operating system used</li>
                  <li>IP address used (if applicable: in anonymized form)</li>
                </ul>

                <p>
                  Data processing is carried out in accordance with Art. 6 (1)
                  point f GDPR on the basis of our legitimate interest in
                  improving the stability and functionality of our website. The
                  data will not be passed on or used in any other way. However,
                  we reserve the right to check the server log files
                  subsequently, if there are any concrete indications of illegal
                  use.
                </p>
                <br></br>
                <h5> 4) Contacting Us</h5>
                <p>
                  When you contact us (e.g. via contact form or e-mail),
                  personal data is collected. Which data is collected in the
                  case of a contact form can be seen from the respective contact
                  form. This data is stored and used exclusively for the purpose
                  of responding to your request or for establishing contact and
                  for the associated technical administration. The legal basis
                  for processing data is our legitimate interest in responding
                  to your request in accordance with Art. 6 (1) point f GDPR. If
                  your contact is aimed at concluding a contract, the additional
                  legal basis for the processing is Art. 6 (1) point b GDPR.
                  Your data will be deleted after final processing of your
                  enquiry; this is the case if it can be inferred from the
                  circumstances that the facts in question have been finally
                  clarified, provided there are no legal storage obligations to
                  the contrary.
                </p>
                <br></br>
                <h5>
                  5) Data Processing When Opening a Customer Account and for
                  Contract Processing
                </h5>
                <p>
                  Pursuant to Art. 6 (1) point b GDPR, personal data will
                  continue to be collected and processed if you provide it to us
                  for the execution of a contract or when opening a customer
                  account. Which data is collected can be seen from the
                  respective input forms. It is possible to delete your customer
                  account at any time. This can be done by sending a message to
                  the above-mentioned address of the controller. We store and
                  use the data provided by you for contract processing. After
                  complete processing of the contract or deletion of your
                  customer account, your data will be blocked in consideration
                  of tax and commercial retention periods and deleted after
                  expiry of these periods, unless you have expressly consented
                  to further use of your data or a legally permitted further use
                  of data has been reserved by our site, about which we will
                  inform you accordingly below.
                </p>
                <br></br>
                <h5>6) Use of Client Data for Direct Advertising</h5>
                <p>
                  6.1 If you subscribe to our e-mail newsletter, we will send
                  you regular information about our offers. The only mandatory
                  information for sending the newsletter is your e-mail address.
                  The indication of additional possible data is voluntary and is
                  used to allow us to address you personally. We use the
                  so-called double opt-in procedure for sending newsletters.
                  This means that we will not send you an e-mail newsletter,
                  unless you have expressly confirmed to us that you agree to
                  the sending of such a newsletter. We will then send you a
                  confirmation e-mail asking you to confirm that you wish to
                  receive future newsletters by clicking on an appropriate link.{" "}
                  <br></br>
                  By activating the confirmation link, you give us your consent
                  to the use of your personal data in accordance with Art. 6 (1)
                  point a GDPR. When you register for the newsletter, we store
                  your IP address entered by the Internet Service Provider (ISP)
                  as well as the date and time of registration so that we can
                  trace any possible misuse of your e-mail address at a later
                  time. The data collected by us when you register for the
                  newsletter will be used exclusively for the purpose of
                  advertising by means of the newsletter. You can unsubscribe
                  from the newsletter at any time via the link provided in the
                  newsletter or by sending a message to the responsible person
                  named above. After your cancellation, your e-mail address will
                  immediately be deleted from our newsletter distribution list,
                  unless you have expressly consented to further use of your
                  data or we reserve the right to use data in excess thereof,
                  which is permitted by law and about which we inform you in
                  this declaration. <br></br>
                  6.2 Our e-mail newsletters are sent via the technical service
                  provider, The Rocket Science Group, LLC d/b/a MailChimp, 675
                  Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA
                  (https://mailchimp.com), to whom we pass on the data you
                  provided when registering for the newsletter. This disclosure
                  is made in accordance with Art. 6 (1) point f GDPR and serves
                  our legitimate interest in the use of an effective, secure and
                  user-friendly newsletter system. Please note that your data is
                  usually transferred to a MailChimp server in the USA and
                  stored there. <br></br>
                  MailChimp uses this information to send and statistically
                  evaluate the newsletter on our behalf. For evaluation
                  purposes, the e-mails sent contain so-called web beacons or
                  tracking pixels, which represent single-pixel image files
                  stored on our website. This allows us to determine whether a
                  newsletter message has been opened and which links have been
                  clicked on. In addition, technical information is recorded
                  (e.g. time of retrieval, IP address, browser type and
                  operating system). The data is raised exclusively in a
                  pseudonymized format and is not linked with other personal
                  data from you. A direct personal relationship is excluded.
                  This data is used exclusively for statistical analysis of
                  newsletter campaigns. The results of these analyses can be
                  used to better adapt future newsletters to the interests of
                  the recipients. <br></br>
                  If you wish to object to the data analysis for statistical
                  evaluation purposes, you must unsubscribe from the newsletter.
                  Furthermore, MailChimp can use this data according to Art. 6
                  (1) point f GDPR even on the basis of its own legitimate
                  interest in the demand-oriented design and optimization of the
                  service as well as for market research purposes, for example
                  to determine from which countries recipients come. However,
                  MailChimp does not use the data of our newsletter recipients
                  to address those recipients or pass that data on to third
                  parties.<br></br>
                  To protect your data in the USA, we have concluded a data
                  processing agreement ("Data-Processing-Agreement") with
                  MailChimp on the basis of the standard contractual clauses of
                  the European Commission to enable the transmission of your
                  personal data to MailChimp. If you are interested in viewing
                  this data processing agreement, you can do so at the following
                  Internet link: <br></br>
                  <a
                    href="https://mailchimp.com/legal/data-processing-addendum/"
                    target="_blank"
                  >
                    mailchimp.com/legal/data-processing-addendum/
                  </a>
                  <br></br>
                  MailChimp's privacy policy can be viewed at:
                  <a
                    href="https://mailchimp.com/legal/privacy/"
                    target="_blank"
                  >
                    mailchimp.com/legal/privacy/
                  </a>
                </p>
                <br></br>
                <h5>7) Processing of Data for the Purpose of Order Handling</h5>
                <p>
                  The personal data collected by us will be passed on to the
                  transport company commissioned with the delivery within the
                  scope of contract processing, insofar as this is necessary for
                  the delivery of the goods. We will pass on your payment data
                  to the commissioned credit institution within the framework of
                  payment processing, if this is necessary for payment handling.
                  If payment service providers are used, we explicitly inform
                  you of this below. The legal basis for the transfer of data is
                  Art. 6 (1) point b GDPR.
                </p>
                <br></br>
                <h5>8) Use of Videos</h5>
                <p>
                  Use of YouTube Videos <br></br>
                  This website uses the YouTube embedding function for display
                  and playback of videos offered by the provider YouTube, which
                  belongs to Google Ireland Limited, Gordon House, 4 Barrow St,
                  Dublin, D04 ESW5, Ireland ("Google"). <br></br>
                  To this end, the extended data protection mode is used to
                  ensure, according to provider information, that user
                  information will only be stored once the playback function of
                  the video is started. When the playback of embedded YouTube
                  videos is started, the provider sets "YouTube" cookies in
                  order to collect information about user behavior. According to
                  indications from YouTube, the use of those cookies is
                  intended, among other things, to record video statistics, to
                  improve user-friendliness and to avoid improper actions. If
                  you are logged in to Google, your information will be directly
                  associated with your account when you click on a video. If you
                  do not wish to be associated with your profile on YouTube, you
                  must log out before activating the button. Google saves your
                  data (even for users who are not logged in) as usage profiles
                  and evaluates them. Such an evaluation takes place in
                  particular according to Art. 6 (1) point f GDPR, on the basis
                  of the legitimate interests of Google in the insertion of
                  personalized advertising, market research and/or
                  demand-oriented design of its website. You have the right to
                  object to the creation of these user profiles, whereby you
                  must contact YouTube to exercise this right. When using
                  YouTube, personal data may also be transmitted to the servers
                  of Google LLC. in the USA. <br></br>
                  Regardless of whether the embedded video is played back, a
                  connection to the Google network "double click" is established
                  when visiting this website. This may trigger further data
                  processing beyond our control. <br></br> Further information
                  on YouTube's privacy policy can be found in the provider's
                  data protection declaration at: <br></br>
                  <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                  >
                    www.google.com/policies/privacy/
                  </a>
                  . <br></br>
                  To the extent required by law, we have obtained your consent
                  to the processing of your data as described in accordance with
                  Art. 6 (1) point a GDPR. You can withdraw your consent at any
                  time with effect for the future. In order to exercise your
                  right of withdrawal, please follow the procedure described
                  above.
                </p>
                <br></br>
                <h5>9) Rights of the Data Subject</h5>
                <p>
                  9.1 The applicable data protection law grants you the
                  following comprehensive rights of data subjects (rights of
                  information and intervention) vis-à-vis the data controller
                  with regard to the processing of your personal data: <br></br>
                  - Right of access by the data subject pursuant to Art. 15
                  GDPR: You shall have the right to receive the following
                  information: The personal data processed by us; the purposes
                  of the processing; the categories of processed personal data;
                  the recipients or categories of recipients to whom the
                  personal data have been or will be disclosed; the envisaged
                  period for which the personal data will be stored, or, if not
                  possible, the criteria used to determine that period; the
                  existence of the right to request from the controller
                  rectification or erasure of personal data or restriction of
                  processing personal data concerning the data subject or to
                  object to such processing; the right to lodge a complaint with
                  a supervisory authority; where the personal are not collected
                  from the data subject, any available information as to their
                  source; the existence of automated decision-making, including
                  profiling and at least in those cases, meaningful information
                  about the logic involved, as well as the significance and
                  envisaged consequences of such processing for the data
                  subject; the appropriate safeguards pursuant to Article 46
                  when personal data is transferred to a third country.
                  <br></br>- Right to rectification pursuant to Art. 16 GDPR:
                  You have the right to obtain from the controller without undue
                  delay the rectification of inaccurate personal data concerning
                  you and/or the right to have incomplete personal data
                  completed which are stored by us. <br></br>- Right to erasure
                  (“right to be forgotten”) pursuant to Art. 17 GDPR: You have
                  the right to obtain from the controller the erasure of
                  personal data concerning you if the conditions of Art. 17 (2)
                  GDPR are fulfilled. However, this right will not apply for
                  exercising the freedom of expression and information, for
                  compliance with a legal obligation, for reasons of public
                  interest or for the establishment, exercise or defense of
                  legal claims. <br></br>- Right to restriction of processing
                  pursuant to Art. 18 GDPR: You have the right to obtain from
                  the controller restriction of processing your personal data
                  for the following reasons: As long as the accuracy of your
                  personal data contested by you will be verified. If you oppose
                  the erasure of your personal data because of unlawful
                  processing and you request the restriction of their use
                  instead. If you require the personal data for the
                  establishment, exercise or defense of legal claims, once we no
                  longer need those data for the purposes of the processing. If
                  you have objected to processing on grounds relating to your
                  personal situation pending the verification whether our
                  legitimate grounds override your grounds. <br></br>- Right to
                  be informed pursuant to Art. 19 GDPR: If you have asserted the
                  right of rectification, erasure or restriction of processing
                  against the controller, he is obliged to communicate to each
                  recipient to whom the personal date has been disclosed any
                  rectification or erasure of personal data or restriction of
                  processing, unless this proves impossible or involves
                  disproportionate effort. You have the right to be informed
                  about those recipients. <br></br>- Right to data portability
                  pursuant to Art. 20 GDPR: You shall have the right to receive
                  the personal data concerning you, which you have provided to
                  us, in a structured, commonly used and machine-readable format
                  or to require that those data be transmitted to another
                  controller, where technically feasible. <br></br>- Right to
                  withdraw a given consent pursuant to Art. 7 (3) GDPR: You have
                  the right to withdraw your consent for the processing of
                  personal data at any time with effect for the future. In the
                  event of withdrawal, we will immediately erase the data
                  concerned, unless further processing can be based on a legal
                  basis for processing without consent. The withdrawal of
                  consent shall not affect the lawfulness of processing based on
                  consent before its withdrawal. <br></br>- Right to lodge a
                  complaint pursuant to Art. 77 GDPR: Without prejudice to any
                  other administrative or judicial remedy, you have the right to
                  lodge a complaint with a supervisory authority, in particular
                  in the Member State of your habitual residence, place of work
                  or place of the alleged infringement if you consider that the
                  processing of personal data relating to you infringes the
                  GDPR. <br></br>
                  9.2 RIGHT TO OBJECT <br></br>
                  IF, WITHIN THE FRAMEWORK OF A CONSIDERATION OF INTERESTS, WE
                  PROCESS YOUR PERSONAL DATA ON THE BASIS OF OUR PREDOMINANT
                  LEGITIMATE INTEREST, YOU HAVE THE RIGHT AT ANY TIME TO OBJECT
                  TO THIS PROCESSING WITH EFFECT FOR THE FUTURE ON THE GROUNDS
                  THAT ARISE FROM YOUR PARTICULAR SITUATION. IF YOU EXERCISE
                  YOUR RIGHT TO OBJECT, WE WILL STOP PROCESSING THE DATA
                  CONCERNED. HOWEVER, WE RESERVE THE RIGHT TO FURTHER PROCESSING
                  IF WE CAN PROVE COMPELLING REASONS WORTHY OF PROTECTION FOR
                  PROCESSING WHICH OUTWEIGH YOUR INTERESTS, FUNDAMENTAL RIGHTS
                  AND FREEDOMS, OR IF THE PROCESSING SERVES TO ASSERT, EXERCISE
                  OR DEFEND LEGAL CLAIMS. IF WE PROCESS YOUR PERSONAL DATA FOR
                  DIRECT MARKETING PURPOSES, YOU HAVE THE RIGHT TO OBJECT AT ANY
                  TIME TO THE PROCESSING OF YOUR PERSONAL DATA WHICH ARE USED
                  FOR DIRECT MARKETING PURPOSES. YOU MAY EXERCISE THE OBJECTION
                  AS DESCRIBED ABOVE. IF YOU EXERCISE YOUR RIGHT TO OBJECT, WE
                  WILL STOP PROCESSING THE DATA CONCERNED FOR DIRECT ADVERTISING
                  PURPOSES.
                  <br></br>
                  <br></br>
                  To exercise your rights, you can contact us using the contact
                  details provided in the legal notice.
                </p>
                <br></br>
                <h5>10) Duration of Storage of Personal Data</h5>
                <p>
                  The duration of the storage of personal data is based on the
                  respective legal basis, the purpose of processing and - if
                  relevant – on the respective legal retention period (e.g.
                  commercial and tax retention periods).
                  <br></br>
                  If personal data is processed on the basis of an express
                  consent pursuant to Art. 6 (1) point a GDPR, this data is
                  stored until the data subject revokes his consent.
                  <br></br>
                  If there are legal storage periods for data that is processed
                  within the framework of legal or similar obligations on the
                  basis of Art. 6 (1) point b GDPR, this data will be routinely
                  deleted after expiry of the storage periods if it is no longer
                  necessary for the fulfillment of the contract or the
                  initiation of the contract and/or if we no longer have a
                  justified interest in further storage. When processing
                  personal data on the basis of Art. 6 (1) point f GDPR, this
                  data is stored until the data subject exercises his right of
                  objection in accordance with Art. 21 (1) GDPR, unless we can
                  provide compelling grounds for processing worthy of protection
                  which outweigh the interests, rights and freedoms of the data
                  subject, or the processing serves to assert, exercise or
                  defend legal claims.
                  <br></br>
                  If personal data is processed for the purpose of direct
                  marketing on the basis of Art. 6 (1) point f GDPR, this data
                  is stored until the data subject exercises his right of
                  objection pursuant to Art. 21 (2) GDPR.
                  <br></br>
                  Unless otherwise stated in the information contained in this
                  declaration on specific processing situations, stored personal
                  data will be deleted if it is no longer necessary for the
                  purposes for which it was collected or otherwise processed.
                </p>
                <br></br>
                <h5>11) Changes to the Privacy Policy</h5>
                <p>
                  We reserve the right to modify this Privacy Policy at any
                  time. Changes will be posted on our website and will be
                  effective from the time of posting. We recommend that you
                  review this Privacy Policy regularly to stay up-to-date.
                </p>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
