import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import {
  ConnectWalletList,
  ConnectWalletButton,
} from "@cardano-foundation/cardano-connect-with-wallet";
import { useCardano } from "@cardano-foundation/cardano-connect-with-wallet";

function HeaderOne() {
  const {
    isEnabled,
    isConnected,
    enabledWallet,
    stakeAddress,
    signMessage,
    connect,
    disconnect,
  } = useCardano() || {};

  function onConnect() {
    console.log("Connected to wallet");
  }
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .push-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, []);

  useEffect(() => {
    $(".menu-tigger").on("click", function () {
      $(".extra-info,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".extra-info,.offcanvas-overly").removeClass("active");
    });
    /*=============================================
	=     Menu sticky & Scroll to top      =
=============================================*/
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
        $("#header-top-fixed").removeClass("header-fixed-position");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
        $("#header-top-fixed").addClass("header-fixed-position");
      }
    });

    /*=============================================
	=    		 Scroll Up  	         =
=============================================*/
    if ($(".scroll-to-target").length) {
      $(".scroll-to-target").on("click", function () {
        var target = $(this).attr("data-target");
        // animate
        $("html, body").animate(
          {
            scrollTop: $(target).offset().top,
          },
          1000
        );
      });
    }
  }, []);

  return (
    <header>
      <div id="header-top-fixed" />
      <div id="sticky-header" className="menu-area main-header">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap main-menu">
                <nav className="menu-nav show">
                  <div className="logo">
                    <a href="/#">
                      <img src="assets/img/logo/logo.png" alt="" />
                    </a>
                  </div>
                  <div className="navbar-wrap push-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li>
                        <NavLink to="/#team">Team</NavLink>
                      </li>
                      <li>
                        <NavLink to="/roadmap">Roadmap</NavLink>
                      </li>
                      <li>
                        <NavLink to="/#faq">FAQ</NavLink>
                      </li>
                      <li>
                        <a href="https://dashboard.adaki.io">Dashboard</a>
                      </li>
                      {/* <li>
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/VisionPaper.pdf`}
                          target="_blank"
                        >
                          Vision Paper
                        </NavLink>
                       
                      </li> */}

                      {/* <li>
                        <Link to="/tournaments">Tournament</Link>
                      </li> */}
                      {/* <li className="menu-item-has-children">
                        <a href="/#">Blog</a>
                        <ul className="sub-menu">
                          <li>
                            <a href="/blog">Our Blog</a>
                          </li>
                          <li>
                            <a href="/blog-details">Blog Details</a>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li className="menu-item-has-children">
                        <a href="/#">Pages</a>
                        <ul className="sub-menu">
                          <li>
                            <a href="/our-squad">Our Squad</a>
                          </li>
                          <li>
                            <a href="/match-single">Match Single</a>
                          </li>
                          <li>
                            <a href="/shop">Our Shop</a>
                          </li>
                          <li>
                            <a href="/shop-details">Shop Details</a>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li>
                        <NavLink to="/contact">contact</NavLink>
                      </li> */}
                    </ul>
                  </div>
                  <div className="header-action">
                    <ul>
                      {/* <li className="header-search">
                        <a href="/#">
                          <i className="fas fa-search" />
                        </a>
                      </li> */}
                      {/* <li className="header-sine">
                        <ConnectWalletButton
                          borderRadius={15}
                          primaryColor="#D81313"
                          fontColor="#FFFFF"
                          messa
                          gap={12}
                          customCSS={
                            "font-family: Helvetica Light,sans-serif; font-size: 0.875rem; font-weight: 700; width: 164px; & > span { padding: 5px 16px; }"
                          }
                          // message="Welcome to Adaki! By connecting your wallet, you confirm that you authorize Adaki to access and read all the information in your wallet, including any NFTs and FTs, as well as all transactions associated with it. Thank you for trusting Adaki with your wallet data."
                          // onSignMessage={onSign}
                          onConnect={onConnect}
                        /> */}
                      {/* <a href="/contact">
                          <i className="far fa-edit" />
                          log-in
                        </a> */}
                      {/* </li> */}
                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile Menu  */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <a href="/#">
                      <img src="assets/img/logo/logo.png" alt="" title="true" />
                    </a>
                  </div>
                  <div className="menu-outer">
                    {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a href="https://twitter.com/AdakiNFT" target="_blank">
                          <span className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="http://discord.adaki.io" target="_blank">
                          <span className="fab fa-discord" />
                        </a>
                      </li>
                      {/* <li>
                        <a href="/#">
                          <span className="fab fa-facebook-square" />
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="/#">
                          <span className="fab fa-pinterest-p" />
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="/#">
                          <span className="fab fa-instagram" />
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="/#">
                          <span className="fab fa-youtube" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
        <div className="header-icon">
          <a href="/#" className="menu-tigger">
            <img src="assets/img/icon/hader_icon.png" alt="" />
          </a>
        </div>
      </div>
      {/* header-search */}
      <div
        className="search-popup-wrap"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="search-close">
          <span>
            <i className="fas fa-times" />
          </span>
        </div>
        <div className="search-wrap text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="title">... Search Here ...</h2>
                <div className="search-form">
                  <form action="#">
                    <input
                      type="text"
                      name="search"
                      placeholder="Type keywords here"
                    />
                    <button className="search-btn">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* header-search-end */}
        {/* offCanvas-menu */}
      </div>

      {/* <div className="offcanvas-overly" /> */}
      {/* offCanvas-menu-end  */}
    </header>
  );
}

export default HeaderOne;
