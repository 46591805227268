import { useEffect } from "react";
// import $ from "jquery";
import { Routes, Route, useLocation } from "react-router-dom";

import IndexThree from "./pages/IndexThree";
import IndexTwo from "./pages/IndexTwo";
import IndexOne from "./pages/IndexOne";
import AboutUs from "./pages/AboutUs";
import Tournament from "./pages/Tournament";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import MatchSingle from "./pages/MatchSingle";
import Squad from "./pages/Squad";
import Shop from "./pages/Shop";
import ShopDetails from "./pages/ShopDetails";
import $ from "jquery";
import ReactGA from "react-ga4";
import Roadmap from "./pages/Roadmap";
import Impressum from "./pages/Impressum";
import Privacy from "./pages/Privacy";
// import WithdrawalPolicy from "./pages/WithdrawalPolicy";
import ScrollToTop from "./scrollToTop";
import TermsOfUse from "./pages/TermsOfUse";
import CookieManager from "./CookieManager";
import ErrorPage from "./pages/ErrorPage";

function App() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  useEffect(() => {
    function preloader() {
      $("#preloader").delay(0).fadeOut();
    }

    $(window).on("load", function () {
      preloader();
    });
  }, []);
  return (
    <div className="App">
      <ScrollToTop />
      <CookieManager />
      <Routes>
        <Route path="/" element={<IndexOne />} />

        {/* <Route path="index-2" element={<IndexTwo />} />
        <Route path="index-3" element={<IndexThree />} /> */}
        {/* <Route path="about-us" element={<AboutUs />} /> */}
        <Route path="roadmap" element={<Roadmap />} />
        {/* <Route path="tournaments" element={<Tournament />} /> */}
        {/* <Route exact path="contact" element={<Contact />} /> */}
        {/* <Route path="blog" element={<Blog />} />
        <Route path="blog-details" element={<BlogDetails />} />
        <Route path="match-single" element={<MatchSingle />} />
        <Route path="our-squad" element={<Squad />} />
        <Route path="shop" element={<Shop />} />
        <Route path="shop-details" element={<ShopDetails />} /> */}
        <Route path="impressum" element={<Impressum />} />
        <Route path="privacy-policy" element={<Privacy />} />
        {/* <Route path="withdrawal-policy" element={<WithdrawalPolicy />} /> */}
        {/* <Route path="terms-of-use" element={<TermsOfUse />} /> */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
