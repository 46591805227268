import React, { useEffect } from "react";
import CounterArea from "../roadmap/CounterArea";
import GamerArea from "../headertwo/GamerArea";
import OfferArea from "../roadmap/OfferArea";
import Game from "./Game";

function Areabg() {
  return (
    <div className="">
      <Game />

      <OfferArea />
    </div>
  );
}

export default Areabg;
