import React from "react";
import "animate.css";
import Tilt from "react-parallax-tilt";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
function Banner() {
  return (
    <div id="parallax" className="banner-area banner-bg">
      <div className="container" style={{ overflow: "hidden" }}>
        <div className="row justify-content-center">
          <div className="col-xxl-8 col-lg-9">
            <div className="banner-content text-center">
              <h4 className="small-title animate__animated animate__fadeInUp animate__delay-.2s">
                Welcome to
              </h4>
              <div
                className="layer animate__animated animate__fadeInUp animate__delay-.4s"
                data-depth="-0.2"
              >
                <MouseParallaxContainer
                  className="parallax"
                  containerStyle={{
                    width: "100%",

                    // display: "grid",
                    // gridTemplateColumns: "auto auto auto auto auto",
                  }}
                  globalFactorX={0.3}
                  globalFactorY={0.3}
                  resetOnLeave
                >
                  <MouseParallaxChild
                    factorX={0.8}
                    factorY={0.8}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "auto",
                      height: "100%",
                    }}
                  >
                    <Tilt
                      perspective={500}
                      glareEnable={true}
                      glareMaxOpacity={0}
                      glarePosition="all"
                      tiltAngleXInitial={0}
                      tiltAngleYInitial={0}
                      tiltMaxAngleX={20}
                      tiltMaxAngleY={30}
                      scale={1.02}
                      trackOnWindow={true}
                      tiltReverse={true}
                    >
                      <img
                        src="assets/img/logo/logowshadow.png"
                        width="80%"
                        alt="logo"
                      />
                    </Tilt>
                  </MouseParallaxChild>
                </MouseParallaxContainer>
              </div>
              {/* <div className="banner-btn animate__animated animate__fadeInUp animate__delay-.6s">
                <a
                  href="http://discord.adaki.io"
                  target="_blank"
                  className="btn"
                >
                  Join now
                  <i className="fas fa-play-circle" />
                </a>
              </div> */}
              <div className="banner-btn animate__animated animate__fadeInUp animate__delay-.6s">
                <a
                  href="https://www.jpg.store/collection/adaki-genesis"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn"
                  style={{ fontSize: "50px", padding: "25px" }}
                >
                  SOLD OUT
                </a>
              </div>
              {/* <a href="/contact" className="text-btn">
                  keep it ki
                </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
