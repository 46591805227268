import React from "react";
import GamerArea from "../headerone/GamerArea";
import OfferArea from "../headerone/OfferArea";
import ShopArea from "../headertwo/ShopArea";
import SquadSingle from "../innerpage/squad/SquadSingle";
import Newsletter from "../Newsletter";
import FAQ from "../singlecomps/FAQ";
import Live from "./Live";
import Partnerships from "./Partnerships";
import Sneeks from "./Sneeks";
import Team from "./Team";

import Tournament from "./Tournament";
import PlayerArea from "./PlayerArea";
import Informations from "./Informations";
import Information2 from "./Informations2";
import Raster from "../singlecomps/Raster";
import Counter from "../Counter";

function AreaBg() {
  return (
    <>
      {/* <Counter /> */}
      <GamerArea />
      <OfferArea />

      <Sneeks />
      <Informations />
      <Information2 />
      <Raster />
      {/* <PlayerArea /> */}
      <Team />
      {/* <Live /> */}
      {/* <ShopArea /> */}

      <FAQ />

      <Partnerships />
      {/* <Newsletter /> */}
    </>
  );
}

export default AreaBg;
