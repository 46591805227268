import React from "react";
import Areabg from "../components/privacy/Areabg";
import Breadcrumb from "../components/privacy/Breadcrumb";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";

function Privacy() {
  return (
    <>
      <HeaderOne />
      <main>
        <Breadcrumb />
        <Areabg />
      </main>
      <Footer />
    </>
  );
}

export default Privacy;
