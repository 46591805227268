import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="footer-top-wrap">
        <div className="container">
          <div className="footer-menu-wrap">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <div className="f-logo">
                  <a href="/index-3">
                    <img src="assets/img/logo/logo.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="footer-menu">
                  <nav>
                    <ul className="navigation">
                      <li>
                        <NavLink to="/">HOME</NavLink>
                      </li>
                      <li>
                        <NavLink to="/roadmap">Roadmap</NavLink>
                      </li>
                      <li>
                        <NavLink to="/#team">Team</NavLink>
                      </li>
                    </ul>
                    <div className="footer-search">
                      {/* <img
                        src="assets/img/logo/logowshadow.png"
                        alt="logo"
                        width={200}
                      /> */}
                      {/* <form action="#">
                        <input type="text" placeholder="Find Here Now" />
                        <button>
                          <i className="fas fa-search" />
                        </button>
                      </form> */}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-quick-link-wrap">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-8">
                <div className="quick-link-list">
                  <ul>
                    <li>
                      <NavLink to="/#faq">FAQ</NavLink>
                      {/* <a href="/#faq">FAQ</a> */}
                    </li>
                    {/* <li>
                      <a href="/contact">Help Center</a>
                    </li> */}
                    {/* <li>
                      <NavLink to="/terms-of-use">Terms of Use</NavLink>
                      
                    </li> */}
                    <li>
                      <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                      {/* <a href="/contact">Privacy</a> */}
                    </li>
                    <li>
                      <NavLink to="/impressum">Impressum</NavLink>
                      {/* <a href="/impressum">Impressum</a> */}
                    </li>
                    {/* <li>
                      <NavLink to="/withdrawal-policy">
                        Withdrawal Policy
                      </NavLink>
                    
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-4">
                <div className="footer-social">
                  <ul>
                    {/* <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li> */}
                    <li>
                      <a href="https://twitter.com/AdakiNFT" target="_blank">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="http://discord.adaki.io" target="_blank">
                        <i className="fab fa-discord" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                        target="_blank"
                      >
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="copyright-text">
                <p>
                  Copyright © 2023 All Rights Reserved By{" "}
                  <span>Adaki / Xunlai UG</span>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              {/* <div className="copyright-img text-center text-md-end">
                <img src="assets/img/images/cart_img.png" alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
