import React from "react";

function OfferArea() {
  return (
    <section className="pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <a href="https://twitter.com/AdakiNFT" target="_blank">
              <div className="offer-item mb-30">
                <h4 className="title">Twitter</h4>
                <p>Join the Twitter Community</p>
                <h5 className="overlay-text">Adaki</h5>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a href="http://discord.adaki.io" target="_blank">
              <div className="offer-item mb-30">
                <h4 className="title">Discord</h4>
                <p>Join the Discord Community</p>
                <h5 className="overlay-text">Adaki</h5>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a
              href="https://www.jpg.store/collection/adakiogpass"
              target="_blank"
            >
              <div className="offer-item mb-30">
                <h4 className="title">JPG.Store</h4>
                <p>Check out our OG-Pass</p>
                <h5 className="overlay-text">Adaki</h5>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OfferArea;
