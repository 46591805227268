import React from "react";
import { motion } from "framer-motion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
import Tilt from "react-parallax-tilt";
import "./Parallax.css";

function GamerArea() {
  return (
    <section className="just-gamer-area pt-50 pb-60">
      <div className="just-gamer-shape" />
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-lg-8 order-2 order-xl-0 z1">
            <div className="just-gamer-content-box">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="Adaki">
                  <div className="section-title">
                    <h2 className="title">
                      What is <span>Adaki?</span>
                    </h2>
                    <p>
                      Welcome to Adaki - the ultimate web3 brand on the Cardano
                      Blockchain. Our name is derived from ADA (currency of the
                      Cardano Blockchain) and KI (Japanese term meaning energy)
                      - and that's exactly what we bring to the NFT space. Our
                      passionate community is dedicated to exploring the endless
                      possibilities of blockchain technology and building the
                      web3 universe. Adaki is the ultimate expression of our
                      vision! With our extensive story and cutting-edge web3
                      content, we're revolutionizing the world of NFTs and
                      bringing the Cardano Blockchain to life like never before.
                      Join us and experience the power of Adaki - the perfect
                      fusion of blockchain technology and creative energy.
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      Experience the power of blockchain technology and creative
                      energy with Adaki
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>The ultimate web3 brand on Cardano Blockchain</p>
                  </div>
                </Tab>

                <Tab eventKey="homprofilee" title="Anime and Cardano">
                  <div className="section-title">
                    <h2 className="title">
                      Anime meets <span>CARDANO</span>
                    </h2>
                    <p>
                      Step into the world of Adaki, the ultimate anime
                      collection on the Cardano Blockchain. Our NFTs are more
                      than just digital art - they're masterpieces, crafted in
                      stunning anime style with a unique design for every fan.
                      Join our community and immerse yourself in a world of
                      anime magic and creativity. Even if you're not a die-hard
                      anime fan, Adaki welcomes you with open arms because for
                      us, community always comes first! Come and be a part of
                      something special - come and be a part of the Adaki
                      family.
                    </p>
                    <p>
                      The Cardano community is like an anime crew: strong,
                      loyal, and ready to work together to create a better
                      future.
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      Collect unique NFTs and join the blockchain revolution
                      with Adaki.
                    </p>
                  </div>
                  <div className="gamer-content">
                    <i className="far fa-arrow-alt-circle-right" />
                    <p>
                      Adaki NFTs: where anime style meets sustainable blockchain
                      technology.
                    </p>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="col-xl-6 col-lg-8">
            <div className="just-gamer-img position-relative text-center text-xxl-end">
              <MouseParallaxContainer
                className="parallax"
                containerStyle={{
                  width: "100%",

                  // display: "grid",
                  // gridTemplateColumns: "auto auto auto auto auto",
                }}
                globalFactorX={0.3}
                globalFactorY={0.3}
                resetOnLeave
              >
                <MouseParallaxChild
                  factorX={0.8}
                  factorY={0.8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "auto",
                    height: "100% + 100px",
                  }}
                >
                  <Tilt
                    perspective={400}
                    glareEnable={true}
                    glareMaxOpacity={0}
                    glarePosition="all"
                    tiltAngleXInitial={0}
                    tiltAngleYInitial={0}
                    tiltMaxAngleX={20}
                    tiltMaxAngleY={20}
                    scale={1.02}
                    tiltReverse={true}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "400px",
                      }}
                    >
                      <img
                        src="assets/img/sneeks/viktor.png"
                        width="300px"
                        alt="Viktor"
                        className="main-img"
                        style={{ borderRadius: "10px" }}
                      />
                    </div>
                  </Tilt>
                </MouseParallaxChild>
              </MouseParallaxContainer>

              {/* <motion.img
                  initial={{x:30, opacity:0}}
                  animate={{x:0, opacity:1}}
                  transition={{delay:.3, duration:.6}}

                   src="assets/img/images/game_img02.png" alt="" className="shape-1 wow fadeInRight" data-wow-delay=".3s" />
                  <motion.img 
                   initial={{x:30, opacity:0}}
                   animate={{x:0, opacity:1}}
                   transition={{delay:0.6,duration:.6}}
                  src="assets/img/images/game_img03.png" alt="" className="shape-2 wow fadeInRight" data-wow-delay=".6s" />
                  <motion.img 
                   initial={{x:-30, opacity:0}}
                   animate={{x:0, opacity:1}}
                   transition={{delay:.9,duration:.9}}
                  src="assets/img/images/game_img04.png" alt="" className="shape-3 wow fadeInLeft" data-wow-delay=".9s" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GamerArea;
