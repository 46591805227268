import React from "react";
import Areabg from "../components/errorpage/Areabg";
import Breadcrumb from "../components/errorpage/Breadcrumb";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";

function ErrorPage() {
  return (
    <>
      <HeaderOne />
      <main>
        <Areabg />
      </main>
      <Footer />
    </>
  );
}

export default ErrorPage;
